<template>
  <div v-if="filters" class="news-filter my-5">
    <v-chip-group show-arrows color="primary" active-class="primary">
      <v-chip
        large
        outlined
        link
        class="font-weight-bold parent-filter py-5 px-5 mr-2 rounded-pill"
        :to="{ name: 'NewsList' }"
        @click="
          () => {
            childrenFilters = null;
          }
        "
        >Tutte</v-chip
      >

      <v-chip
        v-for="filter in filters"
        large
        outlined
        link
        :key="filter.id"
        @click="filterByCategory(filter)"
        class="font-weight-bold parent-filter py-5 px-5 mr-2 rounded-pill"
      >
        {{ filter.name }}
      </v-chip>
    </v-chip-group>

    <!-- CHILDREN CHIPS -->
    <v-chip-group
      color="secondary"
      active-class="secondary"
      v-if="childrenFilters"
    >
      <v-chip
        v-for="childFilter in childrenFilters"
        outlined
        link
        :class="{ active: activeChip === childFilter }"
        :key="childFilter.id"
        @click="
          filterByCategory(childFilter);
          setActiveChip(childFilter);
        "
        class="font-weight-bold child-filter py-5 px-5 mr-2 rounded-pill"
        >{{ childFilter.name }}</v-chip
      >
    </v-chip-group>
  </div>
</template>
<style lang="scss" scoped>
.parent-filter.v-chip--active {
  background-color: var(--v-primary-base) !important;
  color: black;
}
.child-filter.v-chip--active {
  background-color: var(--v-secondary-base) !important;
  // color: white;
}
</style>
<script>
import CMSService from "~/service/cmService";

export default {
  name: "NewsFilter",
  data() {
    return {
      filters: null,
      childrenFilters: null,
      activeChip: null
    };
  },
  props: {
    changePage: { type: String, default: "true" }
  },
  methods: {
    async fetchCategories() {
      const categories = await CMSService.getCustomPostByFilters(
        "categories",
        [
          {
            filterName: "exclude",
            filterValue: 1
          }
        ],
        100
      );
      if (categories && categories.data && categories.data.length) {
        const categoryTree = categories.data.reduce((acc, item) => {
          if (item.parent === 0) {
            const children = categories.data
              .filter(child => child.parent === item.id)
              .map(child => ({
                name: child.name,
                id: child.id,
                parent: child.parent
              }));
            acc.push({
              name: item.name,
              id: item.id,
              parent: item.parent,
              children
            });
          }
          return acc;
        }, []);

        this.filters = categoryTree;
      }
    },

    setActiveChip(chip) {
      this.activeChip = chip;
    },

    filterByCategory(categories) {
      if (categories.parent === 0) {
        this.childrenFilters = categories.children || null;
      }

      let query = {};
      query["cat"] = categories.id.toString();
      if (this.changePage == "true") {
        this.$router.push({ name: "NewsList", query });
      } else {
        this.$router.replace({ query });
      }
    }
  },
  mounted() {
    this.fetchCategories();
  }
};
</script>
