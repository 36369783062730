<template>
  <v-card
    outlined
    class="fill-height my-2 rounded-md d-flex flex-column"
    :to="{
      name: routeName,
      params: { postName: post.slug }
    }"
  >
    <v-img
      height="150"
      max-height="150"
      :src="post.featured_image_url"
      :alt="`Post: ${title}`"
    ></v-img>
    <v-card-subtitle
      class="primary--text font-weight-bold text-uppercase mb-n4"
      v-if="subtitle"
    >
      {{ subtitle }}
    </v-card-subtitle>
    <v-card-subtitle class="py-0" v-if="post.type === 'post'">
      <time>{{ $dayjs(post.date).format("LL") }} </time>
    </v-card-subtitle>
    <h2
      class="card-description pa-3 body-1 font-weight-bold d-flex align-start"
      v-html="title"
    ></h2>
    <v-card-text
      v-if="post.type == 'we_love_trees' || post.subtype == 'we_love_trees'"
      class="post-content card-description-secondary"
      v-html="post.content.rendered || post.content.unrendered"
    />
    <v-spacer />
    <v-card-actions class="ml-auto pa-4 justify-end">
      <v-icon
        :class="
          post.type == 'we_love_trees' || post.subtype == 'we_love_trees'
            ? 'green--text'
            : 'primary--text'
        "
      >
        $arrowForward
      </v-icon>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss" scoped>
.v-card {
  &__text {
    padding-top: 0px;
    padding-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .card-description {
    height: 90px;
  }
  &__actions a {
    text-decoration: none;
  }
}
</style>
<script>
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object,
      required: true
    },
    isWeLoveTrees: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    title() {
      return this.post.title.rendered || this.post.title;
    },
    subtitle() {
      if (this.post.subtype === "recipe" || this.type === "recipe") {
        return this.post.course;
      } else if (
        this.post.subtype === "we_love_trees" ||
        this.type === "we_love_trees"
      ) {
        return this.post.category_names[0];
      } else {
        return null;
      }
    },
    routeName() {
      if (this.post.subtype === "recipe" || this.type === "recipe") {
        return "RecipeDetail";
      } else if (
        this.post.subtype === "we_love_trees" ||
        this.type === "we_love_trees"
      ) {
        return "WeLoveTreesDetail";
      } else if (this.post.subtype === "post" || this.type === "post") {
        return "NewsDetail";
      } else return "Page";
    }
  }
};
</script>
